<template>
  <div>
    <v-form @submit.stop.prevent="onSubmit" auto-complete="no">
      <v-card class="elevation-12">
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>
            <span>RSA Registration</span>
          </v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on: on }">
              <v-btn icon v-on="on" :to="{ name: 'login' }">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Cancel") }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <ApiError :errors="errors" />

          <v-stepper v-model="e1">
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form @submit.prevent="onSubmit" v-model="valid">
                  <v-container>
                    <p>
                      Thank you for your interest in Sony promotions. Please complete the required fields below. A work
                      email is preferred. Once your Dealer has confirmed your eligiblity, you will receive an additional
                      email notification with next steps.
                    </p>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          name="firstName"
                          :label="this.$i18n.translate('First Name')"
                          id="firstName"
                          v-model="form.user.firstName"
                          :rules="rules.firstName"
                          :error-messages="$error.getValidationError(errors, 'firstName')"
                          @input="$error.clearValidationError(errors, 'firstName')"
                          autocomplete="no"
                          class="required"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          name="lastName"
                          :label="this.$i18n.translate('Last Name')"
                          id="lastName"
                          v-model="form.user.lastName"
                          :rules="rules.lastName"
                          :error-messages="$error.getValidationError(errors, 'lastName')"
                          @input="$error.clearValidationError(errors, 'lastName')"
                          autocomplete="no"
                          class="required"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <EmailField
                          name="email"
                          :label="this.$i18n.translate('Work Email')"
                          id="email"
                          v-model="form.user.email"
                          :rules="rules.email"
                          :error-messages="$error.getValidationError(errors, 'email')"
                          @input="$error.clearValidationError(errors, 'email')"
                          autocomplete="no"
                          class="required"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <CountryField
                          :label="this.$i18n.translate('Country')"
                          v-model="form.participant.address.country"
                          :rules="rules.country"
                          :program="program"
                          class="required"
                        />
                      </v-col>
                      <v-col cols="6">
                        <OrganizationField
                          v-model="form.organization"
                          :label="$i18n.translate('Dealer')"
                          :searchFilterJson="getOrganizationFilter"
                          :publicField="true"
                          :rules="rules.organization"
                          class="required"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <TermsAndConditionsView
                          :value="termsAndConditions"
                          @update:valid="validTermsAndConditions = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-btn color="secondary" @click="backToLogin()">
                          Cancel
                        </v-btn>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn
                          color="primary"
                          @click="onSubmit()"
                          :disabled="!valid || !validTermsAndConditions"
                          :loading="loading"
                        >
                          Register
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <h2 class="mb-5">{{ finalTitle }}</h2>
                <p v-html="finalDescription"></p>
                <v-row v-if="!success">
                  <v-col>
                    <v-btn color="primary" @click="e1 -= 1">
                      Back
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn color="secondary" @click="backToLogin()">
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col class="text-right">
                    <v-btn color="primary" @click="backToLogin()">
                      Finish
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import TermsAndConditionsView from "@/gapp-components/components/display/TermsAndConditionsView.vue";
import OrganizationField from "@/gapp-components/components/fields/OrganizationField.vue";
import CountryField from "@/gapp-components/components/fields/CountryField.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError, EmailField, CountryField, OrganizationField, TermsAndConditionsView },
  name: "CcprRegister",
  metaInfo: {
    title: "Register RSA"
  },
  data() {
    return {
      loading: false,
      e1: 1,
      errors: {},
      errorMessage: null,
      success: false,
      valid: false,
      rules: {
        firstName: [v => !!v || "First Name is required."],
        lastName: [v => !!v || "Last Name is required."],
        country: [v => (!!v && !!v.name) || "Country is required."],
        email: [v => !!v || "E-mail is required"],
        organization: [v => (!!v && !!v.id) || "Dealer is required"],
        terms: [v => !!v || "Terms and Conditionas are required"]
      },
      form: {
        programKey: process.env.VUE_APP_PROGRAM_CCPR_KEY,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        participantStatus: {},
        user: {
          firstName: "",
          lastName: "",
          email: "",
          username: ""
        },
        participant: {
          email1: "",
          phoneNumber1: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          },
          participantStatus: { name: "PRE" },
          termAndConditionAccepted: false
        },
        organization: {
          name: "",
          DBA: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          },
          tin: "",
          website: "",
          phoneNumber1: ""
        },
        relationships: [],
        sendMail: true
      },
      program: null,
      termsAndConditions: {},
      validTermsAndConditions: false,
      finalDescription: "",
      finalTitle: ""
    };
  },
  created() {
    // clear existing errors
    this.$store.dispatch("logout");
    // Fetch program
    this.$api.get("/api/programs/byKey/" + process.env.VUE_APP_PROGRAM_CCPR_KEY + "/public").then(({ data }) => {
      this.program = data;
      this.getTermsAndConditions();
    });
  },
  computed: {
    ...mapGetters(["selectedLocale"]),

    getOrganizationFilter() {
      return {
        organizationTypeKeys: ["Y1", "Y2", "Y4", "YC", "Y7"],
        programKey: "SONY-CCPR",
        programGroupKey: "SONY"
      };
    }
  },
  methods: {
    async onSubmit() {
      //Set loading spinner
      this.loading = true;
      this.errors = {};
      this.errorMessage = null;
      Vue.set(this, "errors", []);

      // clear existing errors
      this.$store.dispatch("logout");

      // Preparing the form
      var postForm = {};
      postForm.programKey = this.form.programKey;
      postForm.clientKey = this.form.clientKey;
      postForm.participant = this.form.participant;
      postForm.participant.user = this.form.user;
      postForm.participant.user.username = this.form.user.email;
      postForm.participant.email1 = this.form.user.email;
      postForm.participant.organization = this.form.organization;

      switch (this.form.organization.organizationTypeKey) {
        case "Y1":
          this.form.participantType = {
            participantTypeKey: 530
          };
          this.form.participant.participantType = {
            participantTypeKey: 530
          };
          break;
        case "Y2":
          this.form.participantType = {
            participantTypeKey: 620
          };
          this.form.participant.participantType = {
            participantTypeKey: 620
          };
          break;
        case "Y4":
          this.form.participantType = {
            participantTypeKey: 730
          };
          this.form.participant.participantType = {
            participantTypeKey: 730
          };
          break;
        case "Y7":
          this.form.participantType = {
            participantTypeKey: 930
          };
          this.form.participant.participantType = {
            participantTypeKey: 930
          };
          break;
        case "YC":
          this.form.participantType = {
            participantTypeKey: 830
          };
          this.form.participant.participantType = {
            participantTypeKey: 830
          };
          break;
      }

      postForm.participant.organization.organizationGroups = [{ organizationGroupKey: "IN" }];

      if (postForm.participant.address) {
        if (postForm.participant.address.region) {
          postForm.participant.address.region = { name: postForm.participant.address.region.name };
        }
        if (postForm.participant.address.country) {
          postForm.participant.address.country = { name: postForm.participant.address.country.name };
        }
      }

      if (postForm.participant.organization.address) {
        if (postForm.participant.organization.address.region) {
          postForm.participant.organization.address.region = {
            name: postForm.participant.organization.address.region.name
          };
        }
        if (postForm.participant.organization.address.country) {
          postForm.participant.organization.address.country = {
            name: postForm.participant.organization.address.country.name
          };
        }
      }

      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "EXTENDED_WARRANTY_CLAIM_SUBMISSION"
      };

      // Sending the request
      this.$api
        .postWithCaptcha("/api/participants/enroll/public", postForm, null, "register")
        .then(() => {
          this.e1 = 2;
          this.finalTitle = "Registration completed!";
          this.finalDescription =
            "Thank you for registering on the Consumer Professional Channel Partner Rebate Portal." +
            "<br><br>" +
            "Your request has been forwarded to your organizations Principal Owner and is currently pending approval." +
            "<br><br>" +
            "You will receive an email with additional details once your registration has been reviewed. <br><br>";
          this.success = true;
        })
        .catch(error => {
          let errorDescription = "";
          errorDescription = this.$api.getErrorsFromResponse(error).message;
          this.e1 = 2;
          this.finalTitle = "Registration error";
          this.finalDescription = errorDescription.startsWith("The user is already registered")
            ? "Our system indicates that a RSA registration already exists for the email address you entered. Please click ‘Back’ to review the email address entered OR ‘Cancel’ to utilize ‘Forgot Password' functionality from the Login page. "
            : errorDescription;
          this.success = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    backToLogin() {
      this.$router.push({ name: "login" });
    },

    getTermsAndConditions() {
      let selectedLocaleName = this.selectedLocale.languageType.name;
      if (this.program && this.program.defaultTermsAndConditions) {
        let termsAndConditions = this.program.defaultTermsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (termsAndConditions.length > 0) {
          this.termsAndConditions = termsAndConditions[0];
        }
      }
    }
  }
};
</script>
<style>
.grecaptcha-badge {
  bottom: 40px !important;
}
</style>
